// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-aboutold-js": () => import("./../../../src/pages/aboutold.js" /* webpackChunkName: "component---src-pages-aboutold-js" */),
  "component---src-pages-careers-android-engineer-js": () => import("./../../../src/pages/careers/android-engineer.js" /* webpackChunkName: "component---src-pages-careers-android-engineer-js" */),
  "component---src-pages-careers-delivery-support-agent-js": () => import("./../../../src/pages/careers/delivery-support-agent.js" /* webpackChunkName: "component---src-pages-careers-delivery-support-agent-js" */),
  "component---src-pages-careers-dev-ops-engineer-js": () => import("./../../../src/pages/careers/dev-ops-engineer.js" /* webpackChunkName: "component---src-pages-careers-dev-ops-engineer-js" */),
  "component---src-pages-careers-full-stack-software-engineer-js": () => import("./../../../src/pages/careers/full-stack-software-engineer.js" /* webpackChunkName: "component---src-pages-careers-full-stack-software-engineer-js" */),
  "component---src-pages-careers-graphic-designer-js": () => import("./../../../src/pages/careers/graphic-designer.js" /* webpackChunkName: "component---src-pages-careers-graphic-designer-js" */),
  "component---src-pages-careers-growth-marketing-lead-js": () => import("./../../../src/pages/careers/growth-marketing-lead.js" /* webpackChunkName: "component---src-pages-careers-growth-marketing-lead-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-careers-ios-engineer-js": () => import("./../../../src/pages/careers/ios-engineer.js" /* webpackChunkName: "component---src-pages-careers-ios-engineer-js" */),
  "component---src-pages-careers-market-lead-js": () => import("./../../../src/pages/careers/market-lead.js" /* webpackChunkName: "component---src-pages-careers-market-lead-js" */),
  "component---src-pages-careers-market-manager-js": () => import("./../../../src/pages/careers/market-manager.js" /* webpackChunkName: "component---src-pages-careers-market-manager-js" */),
  "component---src-pages-careers-marketing-lead-js": () => import("./../../../src/pages/careers/marketing-lead.js" /* webpackChunkName: "component---src-pages-careers-marketing-lead-js" */),
  "component---src-pages-careers-merchant-success-lead-js": () => import("./../../../src/pages/careers/merchant-success-lead.js" /* webpackChunkName: "component---src-pages-careers-merchant-success-lead-js" */),
  "component---src-pages-careers-sales-representative-js": () => import("./../../../src/pages/careers/sales-representative.js" /* webpackChunkName: "component---src-pages-careers-sales-representative-js" */),
  "component---src-pages-careers-test-automation-engineer-js": () => import("./../../../src/pages/careers/test-automation-engineer.js" /* webpackChunkName: "component---src-pages-careers-test-automation-engineer-js" */),
  "component---src-pages-careers-ux-design-lead-js": () => import("./../../../src/pages/careers/ux-design-lead.js" /* webpackChunkName: "component---src-pages-careers-ux-design-lead-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-covid-19-js": () => import("./../../../src/pages/covid19.js" /* webpackChunkName: "component---src-pages-covid-19-js" */),
  "component---src-pages-developers-api-reference-html-js": () => import("./../../../src/pages/developers/api-reference.html.js" /* webpackChunkName: "component---src-pages-developers-api-reference-html-js" */),
  "component---src-pages-developers-api-v-1-js": () => import("./../../../src/pages/developers/api/v1.js" /* webpackChunkName: "component---src-pages-developers-api-v-1-js" */),
  "component---src-pages-developers-index-js": () => import("./../../../src/pages/developers/index.js" /* webpackChunkName: "component---src-pages-developers-index-js" */),
  "component---src-pages-doty-js": () => import("./../../../src/pages/doty.js" /* webpackChunkName: "component---src-pages-doty-js" */),
  "component---src-pages-driver-code-js": () => import("./../../../src/pages/driver-code.js" /* webpackChunkName: "component---src-pages-driver-code-js" */),
  "component---src-pages-drivers-js": () => import("./../../../src/pages/drivers.js" /* webpackChunkName: "component---src-pages-drivers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-location-js": () => import("./../../../src/pages/location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-merchant-profiles-index-js": () => import("./../../../src/pages/merchant-profiles/index.js" /* webpackChunkName: "component---src-pages-merchant-profiles-index-js" */),
  "component---src-pages-merchant-profiles-la-bottega-nicastro-js": () => import("./../../../src/pages/merchant-profiles/la-bottega-nicastro.js" /* webpackChunkName: "component---src-pages-merchant-profiles-la-bottega-nicastro-js" */),
  "component---src-pages-merchant-profiles-nrml-js": () => import("./../../../src/pages/merchant-profiles/nrml.js" /* webpackChunkName: "component---src-pages-merchant-profiles-nrml-js" */),
  "component---src-pages-merchant-profiles-overflow-js": () => import("./../../../src/pages/merchant-profiles/overflow.js" /* webpackChunkName: "component---src-pages-merchant-profiles-overflow-js" */),
  "component---src-pages-merchants-js": () => import("./../../../src/pages/merchants.js" /* webpackChunkName: "component---src-pages-merchants-js" */),
  "component---src-pages-nolastcall-js": () => import("./../../../src/pages/nolastcall.js" /* webpackChunkName: "component---src-pages-nolastcall-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-pop-bouclair-js": () => import("./../../../src/pages/pop/bouclair.js" /* webpackChunkName: "component---src-pages-pop-bouclair-js" */),
  "component---src-pages-press-christmas-cheer-breakfast-js": () => import("./../../../src/pages/press/christmas-cheer-breakfast.js" /* webpackChunkName: "component---src-pages-press-christmas-cheer-breakfast-js" */),
  "component---src-pages-press-couriers-of-the-year-2021-js": () => import("./../../../src/pages/press/couriers-of-the-year-2021.js" /* webpackChunkName: "component---src-pages-press-couriers-of-the-year-2021-js" */),
  "component---src-pages-press-ctv-ottawa-curbside-pickup-js": () => import("./../../../src/pages/press/ctv-ottawa-curbside-pickup.js" /* webpackChunkName: "component---src-pages-press-ctv-ottawa-curbside-pickup-js" */),
  "component---src-pages-press-elevate-your-christmas-dinner-js": () => import("./../../../src/pages/press/elevate-your-christmas-dinner.js" /* webpackChunkName: "component---src-pages-press-elevate-your-christmas-dinner-js" */),
  "component---src-pages-press-helping-local-businesses-deliver-js": () => import("./../../../src/pages/press/helping-local-businesses-deliver.js" /* webpackChunkName: "component---src-pages-press-helping-local-businesses-deliver-js" */),
  "component---src-pages-press-helping-local-businesses-during-lockdown-js": () => import("./../../../src/pages/press/helping-local-businesses-during-lockdown.js" /* webpackChunkName: "component---src-pages-press-helping-local-businesses-during-lockdown-js" */),
  "component---src-pages-press-hop-for-hope-js": () => import("./../../../src/pages/press/hop-for-hope.js" /* webpackChunkName: "component---src-pages-press-hop-for-hope-js" */),
  "component---src-pages-press-index-js": () => import("./../../../src/pages/press/index.js" /* webpackChunkName: "component---src-pages-press-index-js" */),
  "component---src-pages-press-last-mile-solution-picks-up-speed-js": () => import("./../../../src/pages/press/last-mile-solution-picks-up-speed.js" /* webpackChunkName: "component---src-pages-press-last-mile-solution-picks-up-speed-js" */),
  "component---src-pages-press-love-local-ottawa-trexity-js": () => import("./../../../src/pages/press/love-local-ottawa-trexity.js" /* webpackChunkName: "component---src-pages-press-love-local-ottawa-trexity-js" */),
  "component---src-pages-press-ottawa-company-delivers-help-js": () => import("./../../../src/pages/press/ottawa-company-delivers-help.js" /* webpackChunkName: "component---src-pages-press-ottawa-company-delivers-help-js" */),
  "component---src-pages-press-ottawa-future-tech-sector-js": () => import("./../../../src/pages/press/ottawa-future-tech-sector.js" /* webpackChunkName: "component---src-pages-press-ottawa-future-tech-sector-js" */),
  "component---src-pages-press-ottawa-restaurateurs-pivot-js": () => import("./../../../src/pages/press/ottawa-restaurateurs-pivot.js" /* webpackChunkName: "component---src-pages-press-ottawa-restaurateurs-pivot-js" */),
  "component---src-pages-press-ottawa-teams-up-to-boost-local-js": () => import("./../../../src/pages/press/ottawa-teams-up-to-boost-local.js" /* webpackChunkName: "component---src-pages-press-ottawa-teams-up-to-boost-local-js" */),
  "component---src-pages-press-serving-up-customer-loyalty-js": () => import("./../../../src/pages/press/serving-up-customer-loyalty.js" /* webpackChunkName: "component---src-pages-press-serving-up-customer-loyalty-js" */),
  "component---src-pages-press-tech-veteran-rob-woodbridge-js": () => import("./../../../src/pages/press/tech-veteran-rob-woodbridge.js" /* webpackChunkName: "component---src-pages-press-tech-veteran-rob-woodbridge-js" */),
  "component---src-pages-press-trexity-canatrace-partner-for-covid-19-js": () => import("./../../../src/pages/press/trexity-canatrace-partner-for-covid-19.js" /* webpackChunkName: "component---src-pages-press-trexity-canatrace-partner-for-covid-19-js" */),
  "component---src-pages-press-trexity-launches-in-winnipeg-js": () => import("./../../../src/pages/press/trexity-launches-in-winnipeg.js" /* webpackChunkName: "component---src-pages-press-trexity-launches-in-winnipeg-js" */),
  "component---src-pages-press-zapiet-and-trexity-partner-up-js": () => import("./../../../src/pages/press/zapiet-and-trexity-partner-up.js" /* webpackChunkName: "component---src-pages-press-zapiet-and-trexity-partner-up-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-privacy-policy-shopify-js": () => import("./../../../src/pages/privacy-policy/shopify.js" /* webpackChunkName: "component---src-pages-privacy-policy-shopify-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-update-address-js": () => import("./../../../src/pages/update-address.js" /* webpackChunkName: "component---src-pages-update-address-js" */),
  "component---src-pages-zones-js": () => import("./../../../src/pages/zones.js" /* webpackChunkName: "component---src-pages-zones-js" */)
}

